require('@rails/ujs').start()
require('turbolinks').start()
require('jquery') // for cocoon
require('@nathanvda/cocoon') // for nested forms

import * as bootstrap from 'bootstrap'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'

import axios from 'axios'
window.axios = axios

import '@popperjs/core'
import { patchBootstrap } from './shared_helpers'

import '../stylesheets/application'

document.addEventListener('DOMContentLoaded', function (event) {
  patchBootstrap()

  const notyf = new Notyf({
    dismissible: true,
    position: {
      x: 'center',
      y: 'top',
    },
  })
  // alert() & toast() will use notyf popup
  window.notyf = notyf
  window.alert = notyf.error.bind(notyf)
  window.toast = notyf.success.bind(notyf)

  if (document.querySelector('#videoGalleryPage')) {
    console.log('Init video gallery tracking')
    initVideoGalleryTracking()
  }

  if (document.querySelector('#auditoriumPage')) {
    console.log('Init auditorium tracking')
    sendHeartbeat()
    checkHeartbeat()
    // Turn off since event ended to not track duration for offline auditorium
    // initDurationTracking()
  }

  if (document.querySelector('#programmeModal')) {
    console.log('Init programme modal')
    initDrawerModal('programmeModal')
  }

  if (document.querySelector('#speakersModal')) {
    console.log('Init speakers modal ')
    initDrawerModal('speakersModal')
  }
})

function initDurationTracking() {
  let startTime = Date.now()

  document.addEventListener('visibilitychange', function logData() {
    if (document.visibilityState === 'visible') {
      startTime = Date.now()
    }

    if (document.visibilityState === 'hidden') {
      const spentTime = Date.now() - startTime
      navigator.sendBeacon('/track', spentTime)
    }
  })
}

function initVideoGalleryTracking() {
  const url = window.location.href
  const videoIdMatch = url.match(/\/video-gallery\/(\d+)/)

  if (!(videoIdMatch && videoIdMatch[1])) {
    console.log('No video ID found')
    return
  }

  const videoId = videoIdMatch[1]
  let startTime = Date.now()

  const onEnter = () => {
    startTime = Date.now()
  }

  const onExit = () => {
    const spentTime = Date.now() - startTime
    navigator.sendBeacon('/track-vid', `${videoId},${spentTime}`)
  }

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      onEnter()
    }

    if (document.visibilityState === 'hidden') {
      onExit()
    }
  })

  document.addEventListener('beforeunload', onExit)
  document.addEventListener('unload', onExit)
}

function checkHeartbeat() {
  setInterval(sendHeartbeat, 30000)
}

function sendHeartbeat() {
  axios
    .post('/heartbeat')
    .then((response) => {
      if (response.status == 200) {
        console.log('Heartbeat OK')
      } else {
        resetSession()
      }
    })
    .catch((error) => {
      console.error('Session validation error:', error)
      resetSession()
    })
}

function resetSession() {
  window.location.reload()
}

function initDrawerModal(domId) {
  let closed = false
  const modal = document.getElementById(domId)
  var bsModal = new bootstrap.Modal(modal)

  modal.addEventListener('show.bs.modal', function (event) {
    closed = false
  })

  modal.addEventListener('hide.bs.modal', function (event) {
    if (closed) return
    modal.classList.remove('show')
    event.preventDefault()

    setTimeout(() => {
      closed = true
      bsModal.hide()
    }, 800)
  })
}
